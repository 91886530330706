#ContactBlock{
    padding: 100px  0  100px 0 ;
    background: #FFEFE4;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

body.dark #ContactBlock{
    background: #1F1717;
}

#ContactBlock .left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 0 0 0 50px;
}

#ContactBlock .left h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1F1717;
}

body.dark #ContactBlock .left h2 {
    color: #FFF9F2;
}

#ContactBlock .left div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

#ContactBlock .left div img{
    width: 30px;
    height: 30px;
}

#ContactBlock .left p {
    font-size: 1rem;
    font-weight: 400;
    color: #1F1717;
    text-align: center;
}

body.dark #ContactBlock .left p {
    color: #FFF9F2;
}

#ContactBlock .right{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#ContactBlock .right form{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

#ContactBlock .right form input{
    width: 100%;
    height: 40px;
    border: 1.5px solid #1F1717;
    background: #FFF9F2;
    border-radius: 25px;
    padding: 0  15px;
    outline: none;
    font-size: 1rem;
    font-weight: 400;
}

body.dark #ContactBlock .right form input{
    background: #1F1717;
    color: #FFF9F2;
    border: 1.5px solid #FFF9F2;
}

#ContactBlock .right form textarea{
    font-family: 'Inter', sans-serif;
    width: 100%;
    height: 200px;
    border: 1.5px solid #1F1717;
    background: #FFF9F2;
    border-radius: 25px;
    padding: 15px;
    outline: none;
    font-size: 1rem;
    font-weight: 400;
    resize: none;
}

body.dark #ContactBlock .right form textarea{
    background: #1F1717;
    color: #FFF9F2;
    border: 1.5px solid #FFF9F2;
}

#ContactBlock .right form button{
    width: 100%;
    height: 40px;
    border: none;
    background: #42B0D3;
    border-radius: 25px;
    color: #FFF9F2;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    transition: 0.1s ease-in-out;
}

body.dark #ContactBlock .right form button {
    background: #CE5A67;
}

#ContactBlock .right form button:hover{

    background: #42a0d3;
    transition: 0.1s ease-in-out;
}

body.dark #ContactBlock .right form button:hover {
    background: #ef4a5f;
}

body.dark #ContactBlock .left img{
    border: 1px solid #FFF9F2;
    border-radius: 5px;
}