header{
    padding: 30px 30px;
    margin: 0;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

header>img{
    width: 50px;
    height: 50px;
    transition: 0.2s ease-in-out;
}


header ul{
    width:fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin: 0;
    padding: 0;
}

header ul li{
    transition:0.2s ease-in-out;
}

header ul li a{
    text-decoration: none;
    color: #1F1717;
    font-size: 18px;
    padding: 8px 20px;
    border: 2px solid #1F1717;
    border-radius: 9999px;
    transition: 0.2s ease-in-out;
}

body.dark header ul li a{
    color: #FFF9F2;
    border: 2px solid #FFF9F2;
    transition: 0.2s ease-in-out;
}

header ul li:nth-child(1):hover, header ul li:nth-child(2):hover{
    transform:scale(1.05);
    transition: 0.2s ease-in-out;
}

header ul li:nth-child(3) a {
    color: #42B0D3;
    font-weight: bold;
    border: 3px solid #42B0D3;
    transition: 0.2s ease-in-out;
}

body.dark header ul li:nth-child(3) a{
    color: #CE5A67;
    border: 3px solid #CE5A67;
    transition: 0.2s ease-in-out;
}

header ul li:nth-child(3) a:hover{
    transform:scale(1);
    background: #42B0D3;
    color: #FFF9F2;
    transition: 0.2s ease-in-out;
}

body.dark header ul li:nth-child(3) a:hover{
    background: #CE5A67;
    color: #FFF9F2;
    transition: 0.2s ease-in-out;
}

header .ChangeLanguage{
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

header .ChangeLanguage button{
    width: 100%;
    background:none;
    font-size: 10px;
    border:none;
    box-shadow: none;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:10px;
}

header .ChangeLanguage button img{
    height: 30px;
    width: 30px;
    border-radius:5px;


}

header .ChangeLanguage #dropdown-content{
    display:none;
    position: absolute;
    top:calc(100% + 15px);
    right:0;
    background-color: #FFF9F2;
    flex-direction: column;
    align-items: center;
    border-radius:10px;
    transition: 0.2s ease-in-out;
    border: 1px solid #1F1717;
    z-index: 8;
}

body.dark header .ChangeLanguage #dropdown-content{
    background-color: #1F1717;
    border: 1px solid #FFF9F2;
    transition: 0.2s ease-in-out;
}

header .ChangeLanguage #dropdown-content a{
    font-weight: bold;
    width: 100%;
    color: #1F1717;
    background: white;
    font-size: 10px;
    padding: 10px 20px;
    border-radius: 0;
    border:none;
    box-shadow: none;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:10px;
    transition: 0.2s ease-in-out;
    border-bottom: 1px solid #1F1717;
}

body.dark header .ChangeLanguage #dropdown-content a{
    color: #FFF9F2;
    background: #1F1717;
    border-bottom: 1px solid #FFF9F2;
    transition: 0.2s ease-in-out;
}

header .ChangeLanguage #dropdown-content a:first-child{
    border-radius: 10px 10px 0 0;
}

header .ChangeLanguage #dropdown-content a:last-child{
    border-radius:0 0 10px 10px;
}

header .ChangeLanguage #dropdown-content a:hover{
     background:lightgrey;
        transition: 0.2s ease-in-out;
 }

body.dark header .ChangeLanguage #dropdown-content a:hover {
    background: #747474;
    transition: 0.2s ease-in-out;
}

header .ChangeLanguage #dropdown-content img{
    width:25px;
    height: 25px;
    border-radius:5px;
}



header .ChangeLanguage .show{
    display: flex !important;
}







#toggle {
    display: none;
}


#toggle-label {
    cursor: pointer;
    outline: transparent;
}

#toggle-div {
    height: calc(120px/4);
    width: calc(240px/4);
    background-color: #357bb3;
    border-radius: 100px;
    position: relative;
    overflow: hidden;
    transition: all 300ms ease;
    box-shadow: inset 2px 5px 5px rgba(0, 0, 0, 0.8),
    inset -2px -5px 5px rgba(0, 0, 0, 0.2), 4px 4px 4px rgba(255, 255, 255, 1);
}

body.dark #toggle-div {
    box-shadow: inset 2px 5px 5px rgba(0, 0, 0, 0.5),
    inset -2px -5px 5px rgba(0, 0, 0, 0.2);
    transition: all 300ms ease;
}

.backdrops {
    position: absolute;
    left: calc(10px/4);
    top: calc(10px/4);
    height: calc(100px/4);
    width: calc(100px/4);
    transition: all 300ms ease;
}

.backdrop {
    height: calc(200px/4);
    width: calc(200px/4);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 100%;

    position: absolute;
    left: 30%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 300ms ease;
}

.backdrop::after,
.backdrop::before {
    content: "";
    height: calc(200px/4);
    width: calc(200px/4);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 100%;
    position: absolute;
    transition: all 300ms ease;
}

.backdrop::before {
    left: 15%;
}

.backdrop::after {
    left: 30%;
}

.clouds {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    transition: all 300ms ease;
}

.cloud {
    position: absolute;
    right: 10%;
    top: 50%;
    background-color: #fbfbfb;
    height: calc(20px/4);
    width: calc(100px/4);
    border-radius: 0 100px 100px 100px;
    transform: scale(-0.8, 0.8);
}

.cloud::before {
    content: "";
    position: absolute;
    background-color: #fbfbfb;
    height: calc(20px/4);
    width: calc(80px/4);
    bottom: calc(18px/4);
    left: 0;
    border-radius: 100px 100px 0 0px;
}

.cloud::after {
    content: "";
    position: absolute;
    background-color: #fbfbfb;
    height:calc(40px/4);
    width: calc(40px/4);
    bottom: calc(18px/4);
    left: calc(10px/4);
    border-radius: 100%;
}

.cloud-1 {
    top: 45%;
    right: 22%;
    transform: scale(-0.6, 0.6);
    opacity: 0.5;
}

.cloud-2 {
    top: 20%;
    right: 0%;
    transform: scale(-0.6, 0.6);
    opacity: 0.5;
}

.cloud-3 {
    top: 92%;
    right: 35%;
}

.cloud-4 {
    top: 85%;
    right: 10%;
    transform: scale(-1, 1);
}

.cloud-5 {
    top: 60%;
    right: -18%;
    transform: rotateZ(-50deg) scale(-1, 1);
}

.sun-moon {
    height: calc(100px/4);
    width: calc(100px/4);
    background-color: #f1c428;
    border-radius: 100%;
    box-shadow: inset calc(2px/4) calc(5px/4) calc(3px/4) rgba(255, 255, 255, 0.5),
    inset calc(-2px/4) calc(-5px/4) calc(3px/4) rgba(0, 0, 0, 0.5), calc(5px/4) calc(5px/4) calc(10px/4) rgba(0, 0, 0, 0.5);

    position: absolute;
    left: calc(10px/4);
    top: calc(10px/4);
    transition: 300ms ease;
}




.stars {
    position: absolute;
    height: 100%;
    width: 100%;
    top: -50%;
    left: 0;
    transform: translate(0, -50%);
    transition: all 300ms ease;
}

.star {
    position: absolute;
    left: 10%;
    top: 50%;
    height: calc(6px/4);
    width: calc(6px/4);
    border-radius: 100%;
    background-color: #c4c9d2;
    box-shadow: 0 0 calc(4px/4) #fff;
    animation: twinkle 1s infinite alternate;
}

.star::before {
    content: "";
    position: absolute;
    left: 300%;
    top: 300%;
    height: calc(4px/4);
    width: calc(4px/4);
    border-radius: 100%;
    background-color: #c4c9d2;
    box-shadow: 0 0 calc(4px/4) #fff;
}

.star::after {
    content: "";
    position: absolute;
    left: 400%;
    bottom: 400%;
    height: calc(5px/4);
    width: calc(5px/4);
    border-radius: 100%;
    background-color: #c4c9d2;
    box-shadow: 0 0 calc(4px/4) #fff;
}

.star-2 {
    left: 40%;
    top: 10%;
    transform: rotateZ(75deg) scale(1.1);
    animation-delay: 300ms;
}

.star-3 {
    left: 40%;
    top: 60%;
    transform: rotateZ(150deg) scale(0.8);
    animation-delay: 600ms;
}

.crater {
    display: none;
    position: absolute;
    left: 30%;
    top: 60%;
    transform: translate(-50%, -50%);
    height: calc(30px/4);
    width: calc(30px/4);
    background-color: #949eb2;
    border-radius: 100%;
    box-shadow: inset 0 0 calc(3px/4) rgba(0, 0, 0, 0.4);
}

.crater::before {
    content: "";
    height: calc(15px/4);
    width: calc(15px/4);
    position: absolute;
    top: -100%;
    left: 50%;
    background-color: #949eb2;
    border-radius: 100%;
    box-shadow: inset 0 0 calc(3px/4) rgba(0, 0, 0, 0.4);
}

.crater::after {
    content: "";
    height: calc(20px/4);
    width: calc(20px/4);
    position: absolute;
    bottom: 40%;
    left: 150%;
    background-color: #949eb2;
    border-radius: 100%;
    box-shadow: inset 0 0 calc(3px/4) rgba(0, 0, 0, 0.4);
}

#toggle-div.night {
    background-color: #1d1f2b;
}

input:checked ~ .clouds {
    top: 150%;
}

input:checked ~ .sun-moon {
    left: calc(100% - calc(110px/4));
    background-color: #c4c9d2;
    transform: rotateZ(180deg);
}

input:checked ~ .backdrops {
    left: calc(100% - calc(130px/4));
}

input:checked ~ .sun-moon .crater {
    display: block;
}

input:checked ~ .stars {
    top: 50%;
}


@keyframes twinkle {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.5;
    }
}




/* media queries */

@media (max-width: 768px) {
    header ul li:nth-child(1), header ul li:nth-child(2), header ul li:nth-child(3) {
        display: none;
    }
}




