*{
    box-sizing: border-box;
}
body{
    font-family: 'Inter', sans-serif;
    background-color: #FFF9F2;
    margin: 0;
    padding: 0;
    width: 100%;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    transition: all 0.5s ease;
}

body.dark{
    background-color: #1F1717;
    color: white;
    transition: all 0.5s ease;
}

h1{
    font-size: 2.5rem;
    margin: 0;
    padding: 0;
}

h2{
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
    font-weight: 400;
}

h3{
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
    font-weight: 600;
}

ul{
    margin:0;
    padding:0;
}

li{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

p{
    font-size: 1rem;
    margin: 0;
    padding: 0;
    font-weight: 400;
}

a{
    text-decoration: none;
    color:black;
    transition: all 0.3s ease-in-out;
}

body.dark a{
    color:#FCF5ED;
    transition: all 0.3s ease-in-out;
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}







