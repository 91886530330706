#TechnosBlock {
  padding: 100px 0 100px 0;
  margin-top: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#TechnosBlock .banner {
  top: 0;
  background: #42b0d3;
  position: absolute;
  left: 0;
  width: 100%;
  height: 45%;
}

body.dark #TechnosBlock .banner {
  background: #3488a2;
}

#TechnosBlock .content {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 75%;
  gap: 20px;
}

#TechnosBlock h2 {
  color: #fff9f2;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

#TechnosBlock .content > div {
  height: fit-content;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  background: #fff9f2;
  border: 1px solid #eac0a2;
  border-radius: 25px;
}

#TechnosBlock .techos_subpart {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #eac0a2;
  padding: 30px 0 70px 0;
  gap: 50px;
}

#TechnosBlock .techos_subpart:last-child {
  border-right: none;
}

#TechnosBlock .techos_subpart > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

#TechnosBlock .techos_subpart > div > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

#TechnosBlock .techos_subpart p {
  color: #1f1717;
}

#TechnosBlock img {
  max-height: 70px;
  max-width: 60%;
}
