#StudiesAndExperiencesBlock {
  background: #42b0d3;
  padding: 100px 0 200px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 100px;
}

body.dark #StudiesAndExperiencesBlock {
  background: #3488a2;
}

#StudiesAndExperiencesBlock h2 {
  color: #fff9f2;
  font-weight: 600;
  margin: 0;
  padding: 0;
  margin-bottom: 50px;
}

#StudiesAndExperiencesBlock > div {
  position: relative;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#StudiesAndExperiencesBlock > div > img {
  width: 100%;
}

#StudiesAndExperiencesBlock > div > div {
  position: absolute;
}

#StudiesAndExperiencesBlock .logo {
  width: 50%;
  background: #42b0d3;
  border-radius: 100%;
  padding: 3%;
}

body.dark #StudiesAndExperiencesBlock .logo {
  background: #3488a2;
}

#StudiesAndExperiencesBlock .logo > div {
  width: 100%;
  padding: 10%;
  border-radius: 100%;
  border: 2px dashed #fff9f2;
}
#StudiesAndExperiencesBlock .logo > div > div {
  width: 100%;
  padding: 3%;
  border-radius: 100%;
  background: #fff9f2;
}

#StudiesAndExperiencesBlock .logo > div > div > img {
  width: 100%;
  height: 100%;
  padding: 10px;
  aspect-ratio: 1;
  object-fit: contain;
}

#StudiesAndExperiencesBlock .logo:nth-of-type(1) {
  top: 0;
  transform: translate(-75%, -75%);
}

#StudiesAndExperiencesBlock .logo:nth-of-type(2) {
  top: 33%;
  transform: translate(75%, -50%);
}

#StudiesAndExperiencesBlock .logo:nth-of-type(3) {
  top: 66%;
  transform: translate(-75%, -50%);
}

#StudiesAndExperiencesBlock .logo:nth-of-type(4) {
  bottom: 0;
  transform: translate(75%, 75%);
}

#StudiesAndExperiencesBlock .description:nth-of-type(4) {
  top: 0;
  transform: translate(50%, -50%);
}

#StudiesAndExperiencesBlock .description:nth-of-type(5) {
  top: 0;
  transform: translate(50%, -75%);
}

#StudiesAndExperiencesBlock .description:nth-of-type(6) {
  top: 33%;
  transform: translate(-50%, -50%);
}

#StudiesAndExperiencesBlock .description:nth-of-type(7) {
  bottom: 33%;
  transform: translate(50%, 50%);
}

#StudiesAndExperiencesBlock .description:nth-of-type(8) {
  bottom: 0;
  transform: translate(-50%, 100%);
}

#StudiesAndExperiencesBlock .description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#StudiesAndExperiencesBlock .description h3 {
  width: 100%;
  background: #fff9f2;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  color: #1f1717;
}

#StudiesAndExperiencesBlock .description span {
  margin-top: 5px;
  color: #e0f8ff;
  text-align: center;
}

#StudiesAndExperiencesBlock .description p {
  margin-top: 10px;
  color: #fff9f2;
  text-align: center;
}
